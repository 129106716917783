import React from "react"
import { graphql } from "gatsby"
import Helmet from "react-helmet"

import Layout from "../components/layout"
import { rhythm } from "../utils/typography"

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <Helmet>
          <title>About | {siteTitle}</title>
        </Helmet>
        <article>
          <header>
            <h3
              style={{
                marginBottom: rhythm(1 / 4),
              }}
            >
              About
            </h3>
          </header>
          <section>
            <p>
              Everything Sweden is a community magazine made by Daniel Reed.
              It's all about Expats in Stockholm, for people thinking of moving
              here, living here, and wanting to know more. Sweden is a hard
              place to get started in, so I want to give you all of the info it
              has taken me years to learn.
            </p>
            <p>
              I am Daniel Reed, originally from the UK and moved to Sweden about
              2 years ago. I built Everything Sweden to offer other expats the
              information I wish I had when I moved to Sweden, like how to get a
              personnummer as well as tips that no-one else will tell you like
              “the S1 form is not the right one…you need this instead”.
            </p>
          </section>
        </article>
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
